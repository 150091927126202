<template>
  <div>
    <v-dialog v-model="dialog" max-width="450px" persistent>
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
          <v-icon dark>
            mdi-plus
          </v-icon>
          Create
        </v-btn>
      </template>
      <v-card tile>
        <v-card-title class="primary white--text">
          <span class="text-h5"><v-icon large color="white" class="mr-2">{{ formIcon }}</v-icon>{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row no-gutters>
              <v-col cols="12" class="my-2 d-flex justify-center" v-if="selectedImage">
                <v-img contain max-height="150" :src="selectedImage"></v-img>
              </v-col>
              <v-col cols="12">
                <v-file-input label="Logo" outlined dense @change="getImage('logo')" v-model="editedItem.logo"
                  prepend-icon="" append-icon="mdi-image"></v-file-input>
              </v-col>
              <v-col cols="12">
                <v-text-field outlined dense v-model="editedItem.name" :error-messages="nameErrors" required
                  @input="editedIndex == -1 ? $v.editedItem.name.$touch() : null"
                  @blur="editedIndex == -1 ? $v.editedItem.name.$touch() : null" label="Name"
                  append-icon="mdi-rename-box"></v-text-field>
              </v-col>
              <v-col cols="12" class="my-2 d-flex justify-center" v-if="selectedProtocolImage1">
                <v-img contain max-height="150" :src="selectedProtocolImage1"></v-img>
              </v-col>
              <v-col cols="12">
                <v-file-input label="Protocol Image 1" outlined dense @change="getImage('p1')"
                  v-model="editedItem.protocol_image_1" prepend-icon="" append-icon="mdi-image"
                  accept="image/png, image/jpeg"></v-file-input>
              </v-col>
              <v-col cols="12" class="my-2 d-flex justify-center" v-if="selectedProtocolImage2">
                <v-img contain max-height="150" :src="selectedProtocolImage2"></v-img>
              </v-col>
              <v-col cols="12">
                <v-file-input label="Protocol Image 2" accept="image/png, image/jpeg" outlined dense
                  @change="getImage('p2')" v-model="editedItem.protocol_image_2" prepend-icon=""
                  append-icon="mdi-image"></v-file-input>
              </v-col>
              <v-col cols="12" class="my-2 d-flex justify-center" v-if="selectedProtocolImage3">
                <v-img contain max-height="150" :src="selectedProtocolImage3"></v-img>
              </v-col>
              <v-col cols="12">
                <v-file-input label="Protocol Image 3" accept="image/png, image/jpeg" outlined dense
                  @change="getImage('p3')" v-model="editedItem.protocol_image_3" prepend-icon=""
                  append-icon="mdi-image"></v-file-input>
              </v-col>
              <v-col cols="12" class="my-2 d-flex justify-center" v-if="selectedProtocolImage4">
                <v-img contain max-height="150" :src="selectedProtocolImage4"></v-img>
              </v-col>
              <v-col cols="12">
                <v-file-input label="Protocol Image 4" accept="image/png, image/jpeg" outlined dense
                  @change="getImage('p4')" v-model="editedItem.protocol_image_4" prepend-icon=""
                  append-icon="mdi-image"></v-file-input>
              </v-col>


            </v-row>
            <v-row>
              <v-col>
                <v-alert v-if="error" dense type="error">
                  {{ error }}
                </v-alert>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider class="primary" />
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="error" @click="close">
            Cancel
          </v-btn>
          <v-btn color="success" @click="save">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="error white--text"><v-icon color="white" class="mr-2">mdi-delete</v-icon> You sure you want
          to delete this item?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="closeDelete">Cancel</v-btn>
          <v-btn color="success" @click="deleteItemConfirm">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-data-table :headers="headers" :items="data" sort-by="calories" class="elevation-3">
      <template v-slot:item.logo="{ item }">
        <v-avatar class="profile my-2" color="grey" size="80" tile>
          <v-img contain v-if="item.logo" :src="item.logo"></v-img>
        </v-avatar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2 warning--text" @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <v-icon small @click="deleteItem(item)" class="error--text">
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:no-data>
        <v-progress-circular v-if="loading" indeterminate color="secondary"></v-progress-circular>
        <v-icon v-else x-large color="grey lighten-1">
          mdi-tray-remove
        </v-icon>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { required } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
export default {
  name: 'Politicals',
  mixins: [validationMixin],
  data: () => ({
    headers: [
      {
        text: 'Logo',
        value: 'logo',
        sortable: false,
        align: 'start'
      },
      { text: 'Name', value: 'name' },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    data: [],
    apiUrl: process.env.VUE_APP_API_URL,
    loading: true,
    dialog: false,
    dialogDelete: false,
    error: '',
    editedIndex: -1,
    editedItem: {
      name: '',
      logo: null,
      protocol_image_1: null,
      protocol_image_2: null,
      protocol_image_3: null,
      protocol_image_4: null,
    },
    defaultItem: {
      name: '',
      logo: null,
      protocol_image_1: null,
      protocol_image_2: null,
      protocol_image_3: null,
      protocol_image_4: null,
    },
    selectedImage: null,
    selectedProtocolImage1: null,
    selectedProtocolImage2: null,
    selectedProtocolImage3: null,
    selectedProtocolImage4: null,
  }),
  created() {
    this.getPoliticals();
  },
  methods: {
    editItem(item) {
      this.selectedImage = item.logo
      this.editedIndex = this.data.indexOf(item);
      this.editedItem = Object.assign({}, item);


      // first check if protocol_images is not null
      if (this.editedItem.protocol_images) {
        // then check if protocol_images[0] is not null
        if (this.editedItem.protocol_images[0]) {
          this.selectedProtocolImage1 = this.editedItem.protocol_images[0]
          this.editedItem.protocol_image_1 = this.editedItem.protocol_images[0]
        }
        // then check if protocol_images[1] is not null
        if (this.editedItem.protocol_images[1]) {
          this.selectedProtocolImage2 = this.editedItem.protocol_images[1]
          this.editedItem.protocol_image_2 = this.editedItem.protocol_images[1]
        }
        // then check if protocol_images[2] is not null
        if (this.editedItem.protocol_images[2]) {
          this.selectedProtocolImage3 = this.editedItem.protocol_images[2]
          this.editedItem.protocol_image_3 = this.editedItem.protocol_images[2]
        }
        // then check if protocol_images[3] is not null
        if (this.editedItem.protocol_images[3]) {
          this.selectedProtocolImage4 = this.editedItem.protocol_images[3]
          this.editedItem.protocol_image_4 = this.editedItem.protocol_images[3]
        }
      }
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.deletePolitical(this.editedItem.id)
      this.closeDelete();
    },
    close() {
      this.selectedImage = null
      this.dialog = false;
      this.$v.$reset()
      this.error = ''
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      this.editedIndex == -1 ? this.addPolitical() : this.updatePolitical()
    },
    //Politicals API Calls
    getPoliticals() {
      this.loading = true
      this.$Axios
        .get('/api/v1/political/')
        .then((res) => {
          this.data = res.data;
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          this.loading = false
        })
    },
    getImage(source) {

      console.log(source)

      let formData = new FormData();

      if (source == "p1") this.selectedProtocolImage1 = null;
      if (source == "p2") this.selectedProtocolImage2 = null;
      if (source == "p3") this.selectedProtocolImage3 = null;
      if (source == "p4") this.selectedProtocolImage4 = null;

      if (source == 'p1') formData.append('img', this.editedItem.protocol_image_1);
      if (source == 'p2') formData.append('img', this.editedItem.protocol_image_2);
      if (source == 'p3') formData.append('img', this.editedItem.protocol_image_3);
      if (source == 'p4') formData.append('img', this.editedItem.protocol_image_4);
      if (source == 'logo') formData.append('img', this.editedItem.logo);

      console.log(formData)

      this.$Axios({
        method: 'post',
        url: '/api/v1/util/upload-image',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(({ data }) => {
          console.log(data)

          // ensure data.img is a url and contains https, also it should not be null
          if (data.img && data.img.includes('http')) {
            this.error = ''
          } else {
            return
          }
          
          if (source == 'p1') this.selectedProtocolImage1 = data.img;
          if (source == 'p2') this.selectedProtocolImage2 = data.img;
          if (source == 'p3') this.selectedProtocolImage3 = data.img;
          if (source == 'p4') this.selectedProtocolImage4 = data.img;
          if (source == 'logo') this.selectedImage = data.img;
        })
        .catch((e) => {
          console.log(e)
        });
    },
    addPolitical() {

      this.error = ''
      this.$v.$touch()
      console.log(this.$v)
      if (this.$v.$error) {
        return
      }
      let data = {}
      data.name = this.editedItem.name
      data.logo = this.selectedImage
      data.protocol_images = [
        this.selectedProtocolImage1,
        this.selectedProtocolImage2,
        this.selectedProtocolImage3,
        this.selectedProtocolImage4,
      ]
      this.$Axios.post('/api/v1/political/', data)
        .then(() => {
          this.getPoliticals();
          this.close();
        })
        .catch((e) => {
          console.log(e)
        });
    },
    updatePolitical() {
      this.error = ''
      this.$v.$touch()
      let data = {}
      if (this.editedItem.name) data.name = this.editedItem.name
      if (this.editedItem.logo) data.logo = this.selectedImage

      data.protocol_images = []
      
      if (this.editedItem.protocol_image_1) data.protocol_images.push(this.selectedProtocolImage1)
      if (this.editedItem.protocol_image_2) data.protocol_images.push(this.selectedProtocolImage2)
      if (this.editedItem.protocol_image_3) data.protocol_images.push(this.selectedProtocolImage3)
      if (this.editedItem.protocol_image_4) data.protocol_images.push(this.selectedProtocolImage4)

      console.log(data)
      this.$Axios.put('/api/v1/political/' + this.editedItem.id, data)
        .then(() => {
          this.getPoliticals();
          this.close();
        })
        .catch((e) => {
          console.log(e)
        });
    },
    deletePolitical(id) {
      this.$Axios
        .delete('/api/v1/political/' + id)
        .then(() => {
          this.getPoliticals();
        })
        .catch((e) => {
          console.log(e);
        })
    },
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'New Political' : 'Edit Political';
    },
    formIcon() {
      return this.editedIndex === -1 ? 'mdi-plus' : 'mdi-edit';
    },
    filtredItems() {
      if (this.filter) return this.desserts.filter(item => item.name.includes(this.filter) || item.license.includes(this.filter) || item.email.includes(this.filter) || item.db.includes(this.filter))
      else return this.desserts
    },
    //// Vuelidate Errors
    nameErrors() {
      const errors = []
      if (!this.$v.editedItem.name.$dirty) return errors
      !this.$v.editedItem.name.required && errors.push('Name is required !')
      return errors
    },
    logoErrors() {
      const errors = []
      if (!this.$v.editedItem.logo.$dirty) return errors
      !this.$v.editedItem.logo.required && errors.push('Logo is required !')
      return errors
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  validations: {
    editedItem: {
      name: {
        required
      },
      logo: {
        required,
      },
    },
  },
};
</script>